.custom-media-card{
  .textBlockElement{
    display: flex;
    flex-flow: column;
    background-color: $grey-light;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-bottom: 25px;
    .leftTextImage,
    .rightTextImage{
      @include aspect-ratio(373, 210);
      order: -1;
      width: 100% !important;
      min-height: auto !important;
      float: none;
      margin: 0;
      position: relative;
      border: 3px solid $grey-light;
      img{
        position: absolute;
        width: 100% !important;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      &:after{
        position: absolute;
        display: block;
        top: 0; bottom: 0; right: 0; left: 0;
        background-image: linear-gradient(to top, black 0%, transparent 50%);
        opacity: .5;
      }
    }
    > h3{
      margin: 20px 0 0 0;
      font-family: $font-secondary;
      line-height: 1.2;
      color: $grey-dark;
      text-transform: uppercase;
    }
    .text{
      margin: 10px 0 0 0;
    }
  }
}
