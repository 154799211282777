.theme-search-wrapper{
  position: relative;
}

.custom-network-toggle{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $grey-medium;
  padding: 10px 15px;
  color: #FFF;
  font-style: normal;
  text-transform: uppercase;
  font-size: 12px;
  font-family: $font-secondary;
  position: relative;
  transition: background-color $transition-linear;
  &:hover{ background-color: $grey-dark; }
  &:after{
    content: "\f0d7";
    font-family: FontAwesome;
    margin-left: 10px;
    font-size: 28px;
    line-height: 12px;
    transform: translateY(-.1em);
  }
  .title-sponsor + &{
    margin-left: 40px;
    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: -20px;
      bottom: 0;
      width: 1px;
      height: 100%;
      background-color: $primary-color;
    }

  }
}

.custom-network-menu-container{
  display: none;
  background-color: rgba(white, .95);
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 999;
  border-bottom: 2px solid $grey-medium;
  .custom-network-menu{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    max-width: $content-area-width;
    width: 100%;
    margin: auto;
    padding: 15px;
  }
  .network-item{
    padding: 15px;
    width: 90px;
    height: 90px;
    a{
      display: block;
      width: 60px;
      height: 60px;
    }
    img{
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
      object-position: center;
    }
  }
}

.custom-network-is-active{
  .custom-network-toggle{
    background-color: $grey-dark;
    &:after{ content: "\f0d8"; }
  }
  .custom-network-menu-container{ display: block; }
}
.custom-progress{ display: none; }
.custom-network-is-loading{
  .custom-progress {
    display: block;
    margin: 10px auto 0;
    width: 70px;
    text-align: center;
    > span {
      width: 18px;
      height: 18px;
      // background-color: $primary-color;
      border-radius: 100%;
      display: inline-block;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      &:nth-child(1) { animation-delay: -0.32s; }
      &:nth-child(2) { animation-delay: -0.16s; }
    }
  }
  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      background-color: $grey-dark;
      transform: scale(0);
    } 40% {
      background-color: $primary-color;
      transform: scale(1.0);
    }
  }
}

.custom-network-is-unreachable{
  .custom-network-menu{
    &:after{
      content: "Content Unavailable.";
      text-align: center;
    }
  }
}
