.padding {
  $padding-desktop: 25px;
  $padding-mobile: 13px;
  padding-top: $padding-desktop;
  padding-bottom: $padding-desktop;
  @media screen and (max-width: $breakpoint-sm-max){
    padding-top: $padding-mobile;
    padding-bottom: $padding-mobile;
  }
  &-top {
    padding-top: $padding-desktop;
    @media screen and (max-width: $breakpoint-sm-max){
      padding-top: $padding-mobile;
    }
    &-mobile {
      @media screen and (max-width: $breakpoint-sm-max){
        padding-top: $padding-mobile;
      }
    }
  }
  &-bottom {
    padding-bottom: $padding-desktop;
    @media screen and (max-width: $breakpoint-sm-max){
      padding-bottom: $padding-mobile;
    }
    &-mobile {
      @media screen and (max-width: $breakpoint-sm-max){
        padding-bottom: $padding-mobile;
      }
    }
  }
}
.column-gutter-none{
  &.layoutContainer,
  > .column{
    padding-left: 0;
    padding-right: 0;
  }
}
.compact {
  &.pageEl .pageElement,
  .pageEl .pageElement {
    margin-bottom: 0;
    margin-top: 0;
  }
  &-top {
    &.pageEl .pageElement,
    .pageEl .pageElement {
      margin-top: 0;
    }
  }
  &-bottom {
    &.pageEl .pageElement,
    .pageEl .pageElement {
      margin-bottom: 0;
    }
  }
}
.layoutContainer,
.connect-wrapper{
  &.background{
    &-grey-medium{ background-color: $grey-medium; }
    &-grey-light{ background-color: $grey-light; }
    &-grey-dark{ background-color: $grey-dark; }
    &-primary{ background-color: $primary-color; }
    &-white{ background-color: #FFF; }
    &-primary-net {
      background-color: $primary-color;
      background-image: url(../images/primary-net-1x.jpg);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
    }
    &-soccer{
      background-color: white;
      background-image: url(../images/soccer-1x.jpg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
}
.connect-wrapper{
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  .connect.layoutContainer{
    background: none;
  }
}

// Headers
.header-special{
  .textBlockElement > h3,
  .pageElement > h3:first-child{ @include element-header; }
  &.header-light .pageElement > h3:first-child { @include element-header($color: white); }
  &.header-center .pageElement > h3:first-child { text-align: center; }
}
.textBlockElement > h3:only-child{ margin-bottom: 0; }


// .header-image-experimental{
//   .textBlockElement{
//     position: relative;
//     > h3{
//       text-align: center;
//       z-index: 1;
//       position: relative;
//       color: #FFF;
//       min-height: 180px;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       margin: 0;
//     }
//   }
//   .originalTextImage{
//     position: absolute;
//     top: 0; bottom: 0; left: 0; right: 0;
//     pointer-events: none;
//     margin: 0;
//     background-color: rgba(35, 31, 32, 0.2);
//     background-image: linear-gradient(135deg, transparent 0%, transparent 50%, $primary-color 50.05%, $primary-color 100%);
//     opacity: .9;
//     img{
//       max-width: 100%;
//       display: block;
//       margin: auto;
//       opacity: 1;
//       width: 100%;
//       height: 100%;
//       object-fit: cover;
//       object-position: center;
//       mix-blend-mode: multiply;
//     }
//   }
// }


.header-image{
  .textBlockElement{
    position: relative;
    z-index: 0;
    > h3{
      margin: 0;
      min-height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include element-header($color: white)
      text-align: center;
      // background-image: linear-gradient(135deg, transparent 0%, transparent 50%, $primary-color 50.05%, $primary-color 100%);
      background-image: linear-gradient(to top, rgba($grey-dark, 0.1) 0%, transparent 30%), url(../images/image-overlay-angled.svg);
      background-size: cover, cover, cover;
      background-position: center center;
      background-blend-mode: multiply;
    }
  }
  .original,
  .small,
  .medium,
  .large{
    &TextImage{
      margin: 0 0 10px 0;
      float: none;
      img{ display: none; }
    }
  }
}

.photo-cta{
  &.sponsor-gatorade .heroPhotoElement{
    position: relative;
    &:before{
      content: '';
      display: block;

      background-image: url(../images/sponsor_gatorade.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      opacity: .5;
      position: absolute;
      top: 5%; right: 0; bottom: 5%;
      left: 60%;
    }
  }
  img, br, .cutline{ display: none; }
  .heroPhotoElement{
    background-image: linear-gradient(to top, rgba($grey-dark, 0.1) 0%, transparent 30%), url(../images/image-overlay-angled.svg);
    background-size: cover, cover, cover;
    background-position: center center;
    background-blend-mode: multiply;
    min-height: 125px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include element-header($color: white)
    text-align: center;
    p{
      margin: 0;
    }
    a{
      color: white;
      z-index: 1;
    }

  }
  .photo-cta-link{
    @include global-link-icon-character;
    &:after{
      color: $grey-light;
      margin-left: 10px;
      transition: margin-left $transition-linear;
    }
    &:hover{
      &:after{
        margin-left: 20px;
      }
    }
  }

}

.split-highlight{
  color: $primary-color;
}
