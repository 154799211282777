.mediaSlider{
  > h2:empty,
  > p:empty{
    display: none;
  }
}
.sn-media-slider .slider .slide-title,
.sn-media-slider .slider .slide-description{
  &:empty{ display: none; }
}

$mse-paging-inside: true !default;
$mse-paging-height: 15px !default;
$mse-paging-item-width: 15px !default;
$mse-paging-item-height: 15px !default;
$mse-paging-item-space-between: 10px !default;
$mse-paging-item-background: $grey-medium !default;
$mse-paging-item-background-hover: $primary-color !default;
$mse-paging-item-background-active: $primary-color !default;

// Pagination Style
.sn-media-slider{
  .slider-pagination{
    height: $mse-paging-height;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    .paging-item{
      width: $mse-paging-item-width;
      height: $mse-paging-item-height;
      background-color: $mse-paging-item-background;
      margin-left: ($mse-paging-item-space-between/2);
      margin-right: ($mse-paging-item-space-between/2);
      &:hover{
        background-color: $mse-paging-item-background-hover;
      }
      &.flex-active{
        background-color: $mse-paging-item-background-active;
      }
    }
  }
}
// Paging Placement
.sn-media-slider .slider{
  // Bottom
  &.bottom-left-paging,
  &.bottom-center-paging,
  &.bottom-right-paging{
    margin-bottom: if($mse-paging-inside == true, 0px , $mse-paging-height * 2);
  }
  &.bottom-center-paging .slider-pagination,
  &.bottom-center-paging .slider-pagination-numbers{
    bottom: $mse-paging-height / 2;
    justify-content: center;
  }
  &.bottom-left-paging .slider-pagination,
  &.bottom-left-paging .slider-pagination-numbers{
    bottom: $mse-paging-height / 2;
    justify-content: flex-start;
  }
  &.bottom-right-paging .slider-pagination,
  &.bottom-right-paging .slider-pagination-numbers{
    bottom: $mse-paging-height / 2;
    justify-content: flex-end;
  }
  // Top
  &.top-left-paging,
  &.top-center-paging,
  &.top-right-paging{
    margin-top: if($mse-paging-inside == true, 0px , $mse-paging-height * 2);
  }
  &.top-center-paging .slider-pagination,
  &.top-center-paging .slider-pagination-numbers{
    top: $mse-paging-height / 2;
    justify-content: center;
  }
  &.top-left-paging .slider-pagination,
  &.top-left-paging .slider-pagination-numbers{
    top: $mse-paging-height / 2;
    justify-content: flex-start;
  }
  &.top-right-paging .slider-pagination,
  &.top-right-paging .slider-pagination-numbers{
    top: $mse-paging-height / 2;
    justify-content: flex-end;
  }
}

//

.sn-media-slider{
  .slider{
    .slide-text{
      display: flex;
      flex-flow: column;
      &.middle-left,
      &.middle-right,
      &.middle-center{
        justify-content: center;
      }
      &.top-left,
      &.top-right,
      &.top-center{
        justify-content: flex-start;
      }
      &.bottom-left,
      &.bottom-right,
      &.bottom-center{
        justify-content: flex-end;
      }

      &.middle-center,
      &.top-center,
      &.bottom-center{
        align-items: center;
        .slide-title,
        .slide-text{
          text-align: center;
          align-items: center;
        }
      }
      &.middle-left,
      &.top-left,
      &.bottom-left{
        align-items: flex-start;
        .slide-title,
        .slide-text{
          text-align: left;
          align-items: flex-start;
        }
      }
      &.middle-right,
      &.top-right,
      &.bottom-right{
        align-items: flex-end;
        .slide-title,
        .slide-text{
          text-align: right;
          align-items: flex-end;
        }
      }
    }
    .slide-title,
    .slide-description{
      width: 100%;
      font-family: $font-secondary;
    }
    .slide-title{
      // display: block; // override
      // color: #FFF; // override
      // font-weight: 900; // override
      // font-size: 72px; // override
      // margin: 0; // override
      // text-shadow: none;  // override
      // line-height: 1; // override
    }
    .slide-description{
      // display: block; // override
      // color: #FFF; // override
      // font-size: 150%; // override
      // margin: 0; // override
      // text-shadow: none; // override
      // line-height: 1; // override
    }
  }
}

// .media-slider-hero{
//   .flexslider{
//     @include mse-logo;
//   }
//   .sn-media-slider .media-wrapper{
//     @include mse-image-overlay;
//   }
//   .sn-media-slider .slides .slide .media-wrapper img{
//     opacity: .9;
//   }
//   .sn-media-slider .slides .slide .slide-overlay{
//     max-width: $content-area-width;
//     margin: 0 auto;
//     left: 50%;
//     transform: translateX(-50%);
//     @include content-container-padding(2);
//     padding-top: $mse-paging-height + 15px;
//     @media screen and (min-width: $breakpoint-md-min){
//       padding-bottom: 100px + $mse-paging-height + 15px;
//     }
//     @media screen and (max-width: $breakpoint-sm-max) {
//       padding-bottom: 45px;
//     }
//   }
//
//   // Adjust for Overlap
//   .sn-media-slider .slider{
//     &.bottom-center-paging .slider-pagination,
//     &.bottom-center-paging .slider-pagination-numbers,
//     &.bottom-left-paging .slider-pagination,
//     &.bottom-left-paging .slider-pagination-numbers,
//     &.bottom-right-paging .slider-pagination,
//     &.bottom-right-paging .slider-pagination-numbers{
//       .user_mode & {
//         @media screen and (min-width: $breakpoint-md-min){
//           bottom: (($mse-paging-height / 2) + 100px);
//         }
//       }
//       @media screen and (max-width: $breakpoint-sm-max) {
//         display: none;
//       }
//     }
//   }
//
// }
.media-slider-hero{
  .sn-media-slider .slides .slide .slide-overlay{
    padding: 50px 100px;
    @media screen and (max-width: 768px){ padding: 30px 5%;}
  }
  .sn-media-slider .slider .slide-title{
    padding-left: 30px;
    font-size: 72px;
    @media screen and (max-width: 768px){ font-size: 40px;}
    line-height: 1;
    max-width: 9.15em;
    text-shadow: 0px 0px 25px rgba(0,0,0,.25);
    position: relative;
    margin: 0;
    &:before{
      content: '';
      position: absolute;
      top: 0; bottom: 0; left: 0;
      width: 10px;
      height: 100%;
      background-color: $primary-color;
      box-shadow: 0px 0px 25px rgba(0,0,0,.25);
    }
  }
  .sn-media-slider .slides .slide .media-wrapper{
    background-color: black;
    position: relative;
    &:before{
      z-index: 1;
      pointer-events: none;
      content: '';
      position: absolute;
      top: 0; bottom: 0; left: 0; right: 0;
      background-image: linear-gradient(to top, rgba($grey-dark, 1) 0%, rgba(black, 0) 25%);
      opacity: .6;
    }
  }
  .sn-media-slider .slides .slide .media-wrapper img{
    opacity: .7;
  }
}
