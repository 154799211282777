.custom-media-quote{
  .textBlockElement{
    padding: 15px;
    background-color: $grey-light;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    .text{
      width: 100%;
      order: 1;
    }
    .leftTextImage,
    .rightTextImage{
      order: 2;
      display: flex;
      flex-flow: row;
      align-items: center;
      width: 100% !important;
      min-height: auto !important;
      margin: 15px 0 0 0;
      img{
        width: 75px !important;
        height: 75px;
        object-fit: cover;
        object-position: center;
        border-radius: 100%;
        margin: 0 15px 0 0;
      }
      br{ display: none; }
      .cutline{
        padding: 0;
        background-color: transparent;
        font-family: $font-secondary;
        font-weight: bold;
        font-style: normal;
        font-size: 24px;
        line-height: 1.1;
      }
    }
    blockquote{
      color: $grey-dark;
      font-family: $font-secondary;
      font-size: 24px;
      line-height: 1.5;
      font-weight: normal;
      font-style: normal;
      padding: 0;
      float: none;
      width: 100%;
      margin: auto;
      &:before,
      &:after{
        font-family: inherit;
        color: currentColor;
      }
    }
  }
}
