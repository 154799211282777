.custom-media-photo{
  .heroPhotoElement{
    @include aspect-ratio(3,1);
    position: relative;
    img{
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    &:after{
      position: absolute;
      display: block;
      top: 0; bottom: 0; right: 0; left: 0;
      background-image: linear-gradient(to top, black 0%, transparent 50%);
      opacity: .5;
    }
  }
}
