/* Event Aggregators */
// 1. Global Styles
// 2. List Views
// 3. List Views - Media Queries
// 4. Five Day View
// 5. Five Day View - Media Queries


$pe-event-agg-date-container-width: 90px;
$pe-event-agg-date-container-margin: 15px;
$pe-event-agg-date-container-padding: null !default;
$pe-event-agg-date-container-background-color: null !default;

$pe-event-agg-date-month-font-family: $font-secondary !default;
$pe-event-agg-date-month-font-color: $grey-dark;
$pe-event-agg-date-month-font-size: 22px;
$pe-event-agg-date-month-line-height: 1.5;
$pe-event-agg-date-month-font-weight: 300;
$pe-event-agg-date-month-text-case: uppercase !default;
$pe-event-agg-date-month-text-align: center !default;

$pe-event-agg-date-day-font-family: $font-secondary !default;
$pe-event-agg-date-day-font-color: $grey-dark;
$pe-event-agg-date-day-font-size: 72px;
$pe-event-agg-date-day-font-weight: 900;
$pe-event-agg-date-day-line-height: .8;
$pe-event-agg-date-day-text-case: uppercase !default;
$pe-event-agg-date-day-text-align: center !default;

$pe-event-agg-title-font-family: null !default;
$pe-event-agg-title-font-color: $grey-dark !default;
$pe-event-agg-title-font-color-hover: $primary-color !default;
$pe-event-agg-title-font-size: 24px !default;
$pe-event-agg-title-font-weight: 700 !default;
$pe-event-agg-title-line-height: null !default;
$pe-event-agg-title-text-case: null !default;
$pe-event-agg-title-text-align: null !default;

$pe-event-agg-time-font-family: null !default;
$pe-event-agg-time-font-color: $grey-medium !default;
$pe-event-agg-time-font-size: 16px !default;
$pe-event-agg-time-font-weight: normal !default;
$pe-event-agg-time-line-height: null !default;
$pe-event-agg-time-text-case: null !default;
$pe-event-agg-time-text-align: null !default;

$pe-event-agg-location-font-family: null !default;
$pe-event-agg-location-font-color: $grey-medium !default;
$pe-event-agg-location-font-color-hover: null !default;
$pe-event-agg-location-font-size: 16px !default;
$pe-event-agg-location-font-weight: normal !default;
$pe-event-agg-location-line-height: null !default;
$pe-event-agg-location-text-case: null !default;
$pe-event-agg-location-text-align: null !default;

$pe-event-agg-tags-font-family: null !default;
$pe-event-agg-tags-font-color: null !default;
$pe-event-agg-tags-link-font-color: $primary-color !default;
$pe-event-agg-tags-link-font-color-hover: $primary-color-dark !default;
$pe-event-agg-tags-font-size: 14px !default;
$pe-event-agg-tags-font-weight: null !default;
$pe-event-agg-tags-line-height: null !default;
$pe-event-agg-tags-text-case: null !default;
$pe-event-agg-tags-text-align: null !default;

$pe-event-agg-table-header-background-color: $primary-color !default;
$pe-event-agg-table-header-text-color: white !default;

@mixin pe-event-agg-small($reset:'false'){
  .vevent{
    @if $reset == true {
      display: grid;
      flex-flow: initial;
      text-align: initial;
    } @else {
      display: flex;
      flex-flow: column;
      text-align: center;
    }
  }
}
@mixin pe-event-agg-hide-options{
  .extendedOptions{ display: none; }
}
@mixin pe-event-agg-hide-tags{
  .tags{ display: none; }
}

// List View: Layout
.eventAggregatorElement{
  .aggHeader{ @include special-header; }
  .vevent{
    padding: 15px 0;
    ~ .vevent { @include line-pattern-border; }
    display: grid;
    width: 100%;
    grid-template-areas: "one gutter two"
                         "one gutter three";
    grid-template-columns: $pe-event-agg-date-container-width $pe-event-agg-date-container-margin 1fr;
    // grid-template-rows: 1fr 1fr;
    .dateImage{ grid-area: one; }
    .summary{ grid-area: two; }
    .details{ grid-area: three; }
    .clearAll{ display: none; }
  }
}

// List View: Media Queries
@include page-element-break(400){
  .eventAggregatorElement { @include pe-event-agg-small; }
}
@media only screen and (min-width: $breakpoint-intermediate-min) and (max-width: $breakpoint-intermediate-max) {
  .layout-25-25-50,
  .layout-50-25-25 {
    .col-md-6,
    .col-md-3 {
      .eventAggregatorElement { @include pe-event-agg-small('true'); }
    }
  }
  .layout-25-50-25 {
    .col-md-6,
    .col-md-3 {
      .eventAggregatorElement { @include pe-event-agg-small; }
    }
  }
}

// List View: Styles
.eventAggregatorElement{
  .vevent{
    .dateImage{
      list-style: none;
      display: flex;
      flex-flow: column;
      justify-content: center;
      // align-items: center;
      background-color: $pe-event-agg-date-container-background-color;
    }
    .month {
      font-family: $fontOne;
      font-size: $pe-event-agg-date-month-font-size;
      font-weight: $pe-event-agg-date-month-font-weight;
      color: $pe-event-agg-date-month-font-color;
      font-family: $pe-event-agg-date-month-font-family;
      line-height: $pe-event-agg-date-month-line-height;
      text-transform: $pe-event-agg-date-month-text-case;
      text-align: $pe-event-agg-date-month-text-align;
    }
    .date {
      font-size: $pe-event-agg-date-day-font-size;
      font-weight: $pe-event-agg-date-day-font-weight;
      color: $pe-event-agg-date-day-font-color;
      font-family: $pe-event-agg-date-day-font-family;
      line-height: $pe-event-agg-date-day-line-height;
      text-transform: $pe-event-agg-date-day-text-case;
      text-align: $pe-event-agg-date-day-text-align;
    }
    .summary{
      a{
        font-family: $pe-event-agg-title-font-family;
        color: $pe-event-agg-title-font-color;
        font-size: $pe-event-agg-title-font-size;
        font-weight: $pe-event-agg-title-font-weight;
        line-height: $pe-event-agg-title-line-height;
        text-transform: $pe-event-agg-title-text-case;
        text-align: $pe-event-agg-title-text-align;
        &:hover{
          color: $pe-event-agg-title-font-color-hover;
        }
      }
    }
    .details{
      list-style: none;
    }
    .time,
    .location{
      display: inline-block;
    }
    .time{
      font-family: $pe-event-agg-time-font-family;
      color: $pe-event-agg-time-font-color;
      font-size: $pe-event-agg-time-font-size;
      font-weight: $pe-event-agg-time-font-weight;
      line-height: $pe-event-agg-time-line-height;
      text-transform: $pe-event-agg-time-text-case;
      text-align: $pe-event-agg-time-text-align;
      abbr{ text-decoration: none; }
    }
    .time{
      margin-right: .7em;
    }
    .location,
    .location > a {
      font-family: $pe-event-agg-location-font-family;
      color: $pe-event-agg-location-font-color;
      font-size: $pe-event-agg-location-font-size;
      font-weight: $pe-event-agg-location-font-weight;
      line-height: $pe-event-agg-location-line-height;
      text-transform: $pe-event-agg-location-text-case;
      text-align: $pe-event-agg-location-text-align;
      &:hover{
        color: $pe-event-agg-location-font-color-hover;
      }
    }
    .tags{
      font-family: $pe-event-agg-tags-font-family;
      color: $pe-event-agg-tags-font-color;
      font-size: $pe-event-agg-tags-font-size;
      font-weight: $pe-event-agg-tags-font-weight;
      line-height: $pe-event-agg-tags-line-height;
      text-transform: $pe-event-agg-tags-text-case;
      text-align: $pe-event-agg-tags-text-align;
      a{
        color: $pe-event-agg-tags-link-font-color;
        &:hover{
          color: $pe-event-agg-tags-link-font-color-hover;
        }
      }
    }
  }
}







// 2. User Filtered View: Styles
.eventAggregatorElement {
  .formElement {
    padding: 10px 0; // This should be better defined in global form styles (or removed from there to be styled per element)
    [id$="_start_date_day"],
    [id$="_end_date_day"] {
      width: 8em;
    }
  }
}


// 4. Five Day View
.eventPreview {
  text-align: left;
  width: 100%;
  table-layout: fixed;
  th {
    @include table-th;
    @include table-th-text;
    word-break: break-word;
    a {
      color:#FFF;
      font-weight: 600;
    }
  }
  td {
    font-size: 12px;
    padding: 10px;
    background: #eee;
    vertical-align: top;
    word-break: break-word;
    &:before {
      content: attr(data-week-view-date)'';
      display: none;
      width: 100%;
      position: relative;
      box-sizing: border-box;
      padding: 7px 12px;
      border-bottom: $table-cell-spacing solid #FFF;
      background: $pe-event-agg-table-header-background-color;
      color: $pe-event-agg-table-header-text-color;
      text-transform: uppercase;
      font-family: $fontOne;
      font-size: 1em;
      line-height: 1.1;
      font-weight: 600;
    }
  }
  td,
  th {
    border: $table-cell-spacing solid #FFF;
    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }
  .event{
    list-style: none;
    a{
      color: $grey-dark;
      font-family: $font-secondary;
      font-size: 16px;
      &:hover{
        color: $primary-color;
      }
    }
    abbr{ text-decoration: none; }
  }
  .event + .event {
    padding-top: 10px;
  }
  .noentry {
    word-break:normal;
  }
}

// 5. Five Day View - Media Queries
@include page-element-break(430) {
  .eventPreview {
    border-top: $table-cell-spacing solid #FFF;
    &,
    tbody,
    tr,
    td {
      display: block;
    }
    thead {
      display: none;
    }
    td {
      border: 0;
      padding: 0;
      margin-bottom: $table-cell-spacing;
      word-break: word-break;
      &:before {
        display: block;
      }
    }
    .event {
      padding: 10px;
      & + .event {
        padding-top: 0;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 999px) {
  .layout-25-25-50,
  .layout-50-25-25 {
    .col-md-6 {
      .eventPreview {
        display: table;
        border-top: 0;
        thead {
          display: table-header-group;
        }
        tbody {
          display: table-row-group;
        }
        tr {
          display: table-row;
        }
        td,
        th {
          display: table-cell;
        }
        td {
          padding: 10px;
          margin-bottom: none;
          word-break: word-break;
          &:before {
            display: none;
          }
        }
        .event {
          padding: 0;
        }
      }
    }
  }
  .layout-25-50-25 {
    .col-md-6 {
      .eventPreview {
        border-top: $table-cell-spacing solid #FFF;
        &,
        tbody,
        tr,
        td {
          display: block;
        }
        thead {
          display: none;
        }
        td {
          border: 0;
          padding: 0;
          margin-bottom: $table-cell-spacing;
          word-break: word-break;
          &:before {
            display: block;
          }
        }
        .event {
          padding: 10px;
          & + .event {
            padding-top: 0;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 891px) and (max-width: 999px) {
  .layout-25-25-50,
  .layout-50-25-25 {
    .col-md-3 {
      .eventPreview {
        display: table;
        border-top: 0;
        thead {
          display: table-header-group;
        }
        tbody {
          display: table-row-group;
        }
        tr {
          display: table-row;
        }
        td,
        th {
          display: table-cell;
        }
        td {
          padding: 10px;
          margin-bottom: none;
          word-break: word-break;
          border: $table-cell-spacing solid #FFF;
          &:first-child {
            border-left: 0;
          }
          &:last-child {
            border-right: 0;
          }
          &:before {
            display: none;
          }
        }
        .event {
          padding: 0;
        }
      }
    }
  }
}


// Custom Styles
.event-aggregator-grid{
  .eventAggregatorElement{
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    @include pe-event-agg-small;
    @include pe-event-agg-hide-options;
    @include pe-event-agg-hide-tags;
    .vevent{
      width: 100%;
      padding: 15px;
      border: 0;
      max-width: #{($content-area-width - ($content-area-padding * 2))/5};
      flex: 0 1 #{($content-area-width - ($content-area-padding * 2))/5};
      @media screen and (max-width: 495px){
        max-width: 100%;
        flex-grow: 1;
        padding: 15px 0;
      }
      .time{
        margin-right: auto;
      }
    }
    .aggHeader{
      text-align: center;
    }
    .aggHeader,
    .extendedOptions{
      width: 100%;
    }
  }
}
