.snFooterContainer{
  background-color: $grey-dark;
  border-top: 5px solid $primary-color;
}
.custom-site-footer{
  display: grid;
  width: 100%;
  max-width: $content-area-width;
  margin: auto;
  grid-template-areas: "one two three";
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: $content-area-padding;
  @media screen and (max-width: $breakpoint-sm-max){
    grid-column-gap: $content-area-padding-mobile;
  }
  padding: 60px $content-area-padding;
  // padding: $content-area-padding;
  // grid-template-rows: 1fr 1fr;
  .custom-footer-col-1{ grid-area: one; }
  .custom-footer-col-2{ grid-area: two; }
  .custom-footer-col-3{ grid-area: three; }
}

.custom-site-footer{
  color: white;
  h3{
    color: white;
    font-family: $font-secondary;
    text-transform: uppercase;
  }
  .footer-links ul{
    list-style: none;
    column-count: 2;
    margin: 0;
    a{
      color: white;
    }
  }
}


.custom-site-footer{
  @media screen and (max-width: $breakpoint-sm-max){
    display: flex;
    flex-flow: column;
    text-align: center;
    padding: 60px $content-area-padding-mobile;
    h3{
      text-align: center !important;
    }
    .footer-links{ display: none; }
    .custom-footer-logo{
      max-width: 325px;
      width: 100%;
      margin: auto auto 30px auto;
    }
  }
}
