.custom-sponsors{
  .column{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .heroPhotoElement{
    padding: 0 15px;
  }
  img{
    max-height: 100px;
    width: auto;
    max-width: 100%;
  }
}
